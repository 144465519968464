<template>
  <div class="about">
    <logo></logo>
    <b-card>
      <b-skeleton width="85%"></b-skeleton>
      <b-skeleton width="45%"></b-skeleton>
      <b-skeleton width="76%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
    </b-card>
  </div>
</template>

<script>

export default {
  components: {
  },
  data(){
    return {
    }
  }
}
</script>
